// translations-en.ts
import { IStrings } from "./IStrings";

const translationsEn: IStrings = {
  loading: "Loading...",
  rsvp: "RSVP",
  showInMap: "Show in map",
  addToCalendar: "Add to calendar",
  rsvpFor: "RSVP for",
  willAttend: "Will attend",
  willNotAttend: "Will not attend",
  dietQuestion: "Do you have any allergies or dietary restrictions?",
  dietPlaceholder: "Write your allergies or dietary restrictions",
  bringAGuest: "Bring a guest",
  removeGuest: "Remove guest",
  additionalGuestName: "Additional guest name",
  additionalGuestdietQuestion: "Does your guest have any allergies?",
  optionalMessage: "Optional message",
  optionalMessagePlaceholder: "Write a message to the host",
  submitRsvp: "Submit RSVP",
  close: "Close",
  errorLoadingData: "Error loading data",
};

export default translationsEn;
