import React, { useState } from 'react';
import {
    Box,
    Button,
    FormControl,
    FormControlLabel,
    FormLabel,
    InputLabel,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    TextField,
    Checkbox,
    Typography
} from '@mui/material';

const RSVPForm: React.FC = () => {
    const [formData, setFormData] = useState({
        attending: '',
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        numberOfPeople: '',
        dietaryPreference: '',
        foodAllergies: '',
    });

    const handleChange = (event: React.ChangeEvent<HTMLInputElement | { name?: string; value: unknown }>) => {
        const { name, value } = event.target;
        // setFormData((prevData) => ({
        //   ...prevData,
        //   [name]: value,
        // }));
    };

    const handleSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        console.log('RSVP Data:', formData);
        // Add form submission logic here (e.g., sending data to a server)
    };

    return (
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 2 }}>
            <FormControl component="fieldset" sx={{ mb: 2 }}>
                <RadioGroup
                    aria-label="attending"
                    name="attending"
                    value={formData.attending}
                    onChange={handleChange}
                >
                    <FormControlLabel value="yes" control={<Radio />} label="Asistiré" />
                    <FormControlLabel value="no" control={<Radio />} label="No asistiré" />
                </RadioGroup>
            </FormControl>
            <TextField
                fullWidth
                label="Nombre"
                name="firstName"
                value={formData.firstName}
                onChange={handleChange}
                sx={{ mb: 2 }}
            />
            <TextField
                fullWidth
                label="Apellido"
                name="lastName"
                value={formData.lastName}
                onChange={handleChange}
                sx={{ mb: 2 }}
            />
            <TextField
                fullWidth
                label="Email"
                name="email"
                type="email"
                value={formData.email}
                onChange={handleChange}
                sx={{ mb: 2 }}
            />
            <TextField
                fullWidth
                label="Teléfono"
                name="phone"
                type="tel"
                value={formData.phone}
                onChange={handleChange}
                sx={{ mb: 2 }}
            />
            <FormControl fullWidth sx={{ mb: 2 }}>
                <InputLabel id="number-of-people-label">¿Cuántos lugares confirma?</InputLabel>
                <Select
                    labelId="number-of-people-label"
                    id="number-of-people"
                    name="numberOfPeople"
                    value={formData.numberOfPeople}
                //   onChange={handleChange}
                >
                    <MenuItem value="1">1</MenuItem>
                    <MenuItem value="2">2</MenuItem>
                    <MenuItem value="3">3</MenuItem>
                    <MenuItem value="4">4</MenuItem>
                    <MenuItem value="5">5</MenuItem>
                </Select>
            </FormControl>
            <FormControl component="fieldset" sx={{ mb: 2 }}>
                <FormLabel component="legend">¿Eres vegano/vegetariano?</FormLabel>
                <RadioGroup
                    aria-label="dietaryPreference"
                    name="dietaryPreference"
                    value={formData.dietaryPreference}
                    onChange={handleChange}
                >
                    <FormControlLabel value="none" control={<Radio />} label="Niguno" />
                    <FormControlLabel value="vegetarian" control={<Radio />} label="Vegetariano" />
                    <FormControlLabel value="vegan" control={<Radio />} label="Vegano" />
                </RadioGroup>
            </FormControl>
            <TextField
                fullWidth
                label="¿Eres alérgico a algún alimento?"
                name="foodAllergies"
                value={formData.foodAllergies}
                onChange={handleChange}
                sx={{ mb: 2 }}
            />
            <Button type="submit" variant="contained" color="primary">
                Enviar
            </Button>
        </Box>
    );
};

export default RSVPForm;
