import * as React from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import Typography from '@mui/material/Typography';
import { Box, Button } from '@mui/material';
import { FaMapMarkerAlt, FaCalendarAlt, FaTshirt } from 'react-icons/fa';

export default function OppositeContentTimeline() {
    return (
        <>
            <Typography variant="h6" component="div" gutterBottom >
                <strong className="text-gray-700">Viernes 29 de Noviembre</strong>
            </Typography>
            <Timeline position="left">
                <TimelineItem>
                    <TimelineOppositeContent color="text.secondary">
                        <Typography variant="body2" component="span">
                            <strong>7:00 pm</strong>
                        </Typography>
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                        <TimelineDot />
                        <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>
                        <Typography variant="h6" component="span" color="text.primary">
                            Rompehielo
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            Casanueva de Las Mañanitas
                        </Typography>
                        <Typography variant="body1" color="text.secondary" ml={1}>
                            Calentando motores
                        </Typography>
                        <Box display="flex" alignItems="center" justifyContent="flex-end" mb={1}>
                            <FaTshirt size={16} />
                            <Typography variant="body2" color="text.secondary" ml={1}>
                                Casual
                            </Typography>
                        </Box>
                        <Button
                            variant="outlined"
                            startIcon={<FaCalendarAlt />}
                            sx={{
                                mt: 1, mb: 1,
                                color: 'text.secondary',
                                borderColor: 'text.secondary',
                                '&:hover': {
                                    borderColor: 'text.secondary',
                                    backgroundColor: 'rgba(0, 0, 0, 0.04)', // Slightly darker on hover
                                },
                            }}
                            onClick={() => window.open('https://maps.app.goo.gl/Rck1XmPtb9c9zjip7', '_blank')}
                        >
                            Agregar al calendario
                        </Button>
                    </TimelineContent>
                </TimelineItem>
            </Timeline>


            <Typography variant="h6" component="div" gutterBottom>
                <strong className="text-gray-700">Sábado 30 de Noviembre</strong>
            </Typography>
            <Timeline position="left">
                <TimelineItem>
                    <TimelineOppositeContent color="text.secondary">
                        <Typography variant="body2" component="span">
                            <strong>3:00 pm</strong>
                        </Typography>
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                        <TimelineDot />
                        <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>
                        <Typography variant="h6" component="span" color="text.secondary">
                            Misa
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            Jardín de Casanueva de Las Mañanitas
                        </Typography>
                        <Typography variant="body1" color="text.secondary" ml={1}>
                            Acompáñanos a unir nuestras vidas en matrimonio
                        </Typography>
                        <Box display="flex" alignItems="center" justifyContent="flex-end" mb={1}>
                            <FaTshirt size={16} />
                            <Typography variant="body2" color="text.secondary" ml={1}>
                                Formal
                            </Typography>
                        </Box>

                        <Button
                            variant="outlined"
                            startIcon={<FaCalendarAlt />}
                            sx={{
                                mt: 1, mb: 1,
                                color: 'text.secondary',
                                borderColor: 'text.secondary',
                                '&:hover': {
                                    borderColor: 'text.secondary',
                                    backgroundColor: 'rgba(0, 0, 0, 0.04)', // Slightly darker on hover
                                },
                            }}
                            onClick={() => window.open('https://maps.app.goo.gl/Rck1XmPtb9c9zjip7', '_blank')}
                        >
                            Agregar al calendario
                        </Button>
                    </TimelineContent>
                </TimelineItem>
                <TimelineItem>
                    <TimelineOppositeContent color="text.secondary">
                        <Typography variant="body2" component="span">
                            <strong>5:00 pm</strong>
                        </Typography>
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                        <TimelineDot />
                        <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>
                        <Typography variant="h6" component="span" color="text.secondary">
                            Recepción
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            Casanueva de Las Mañanitas
                        </Typography>
                        <Typography variant="body1" color="text.secondary" ml={1}>
                            Celebremos juntos
                        </Typography>
                        <Box display="flex" alignItems="center" justifyContent="flex-end" mb={1}>
                            <FaTshirt size={16} />
                            <Typography variant="body2" color="text.secondary" ml={1}>
                                Formal
                            </Typography>
                        </Box>
                        <Button
                            variant="outlined"
                            startIcon={<FaCalendarAlt />}
                            sx={{
                                mt: 1, mb: 1,
                                color: 'text.secondary',
                                borderColor: 'text.secondary',
                                '&:hover': {
                                    borderColor: 'text.secondary',
                                    backgroundColor: 'rgba(0, 0, 0, 0.04)',
                                },
                            }}
                            onClick={() => window.open('https://maps.app.goo.gl/Rck1XmPtb9c9zjip7', '_blank')}
                        >
                            Agregar al calendario
                        </Button>
                    </TimelineContent>
                </TimelineItem>
            </Timeline>



            <Typography variant="h6" component="div" gutterBottom>
                <strong className="text-gray-700">Domingo 1 de diciembre</strong>
            </Typography>
            <Timeline position="left">
                <TimelineItem>
                    <TimelineOppositeContent color="text.secondary">
                        <Typography variant="body2" component="span" color="text.secondary">
                            <strong>11:00 am</strong>
                        </Typography>
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                        <TimelineDot />
                        <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>
                        <Typography variant="h6" component="span" color="text.secondary">
                            Tornaboda
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            Por definir
                        </Typography>
                        <Typography variant="body1" color="text.secondary" ml={1}>
                            Post-wedding brunch
                        </Typography>
                        <Box display="flex" alignItems="center" justifyContent="flex-end" mb={1}>
                            <FaTshirt size={16} />
                            <Typography variant="body2" color="text.secondary" ml={1}>
                                Casual
                            </Typography>
                        </Box>
                        <Button
                            variant="outlined"
                            startIcon={<FaCalendarAlt />}
                            sx={{
                                mt: 1, mb: 1,
                                color: 'text.secondary',
                                borderColor: 'text.secondary',
                                '&:hover': {
                                    borderColor: 'text.secondary',
                                    backgroundColor: 'rgba(0, 0, 0, 0.04)', // Slightly darker on hover
                                },
                            }}
                            onClick={() => window.open('https://maps.app.goo.gl/Rck1XmPtb9c9zjip7', '_blank')}
                        >
                            Agregar al calendario
                        </Button>
                    </TimelineContent>
                </TimelineItem>
            </Timeline >
        </>
    );
}
