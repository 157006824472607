import React from 'react';

interface ContentContainerProps {
    imageSrc: string;
    altText: string;
    children: React.ReactNode;
}

const ContentContainer: React.FC<ContentContainerProps> = ({ imageSrc, altText, children }) => {
    return (
        <div className="max-w-2xl mx-auto my-8 p-4 bg-white">
            <img src={imageSrc} alt={altText} className="w-full h-auto rounded-t-lg" />
            <div className="mt-4 text-center">
                {children}
            </div>
        </div>
    );
};

export default ContentContainer;
