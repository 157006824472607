import React from 'react';
import FadeInSection from '../components/FadeInSection';
import Timeline from '../components/Timeline';
import ContentContainer from '../components/ContentContainer';

const ScheduleSection: React.FC = () => {
    return (
        <FadeInSection>
            <section id="schedule" className="min-h-screen flex items-center justify-center">
                <div className="px-4">
                    <ContentContainer imageSrc="abrilyjc.jpg" altText="Wedding Image">
                        <h2 className="text-2xl text-gray-700 font-bold mb-2">Horario</h2>
                        <Timeline />
                    </ContentContainer>
                </div>
            </section>
        </FadeInSection>
    );
};

export default ScheduleSection;
