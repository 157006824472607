import React from 'react';
import FadeInSection from '../components/FadeInSection';
import ContentContainer from '../components/ContentContainer';

const RegistrySection: React.FC = () => {
    return (
        <FadeInSection>
            <section id="registry" className="min-h-screen flex items-center justify-center">
                <div className="px-4">
                    <ContentContainer imageSrc="abrilyjc.jpg" altText="Wedding Image">
                        <h2 className="text-2xl text-gray-700  font-bold mb-2">Mesa de Regalos</h2>
                        <p className="text-gray-700 mb-4">
                            Su amistad y compañía en nuestra boda es el mejor regalo que podríamos recibir.
                            Sin embargo, si desean obsequiarnos algo más, les compartimos nuestra mesa de regalos.
                        </p>
                        <h3 className="text-xl text-gray-700 font-bold mb-2">Transferencia electrónica</h3>
                        <p className="text-gray-700">
                            Para aquellos que prefieren una transferencia bancaria, aquí están los detalles de nuestra cuenta:
                        </p>
                        <p className="text-gray-700 mt-2">
                            <strong>Banco:</strong> Nombre del Banco<br />
                            <strong>Titular de la cuenta:</strong> Abril & Juan Carlos<br />
                            <strong>Número de cuenta:</strong> 1234567890<br />
                            <strong>CLABE:</strong> 123456789012345678<br />
                            <strong>Venmo:</strong> @jcfontecha
                        </p>
                    </ContentContainer>
                </div>
            </section>
        </FadeInSection>
    );
};

export default RegistrySection;
