import React from 'react';
import FadeInSection from '../components/FadeInSection';
import RSVPForm from '../components/RSVPForm';
import ContentContainer from '../components/ContentContainer';

const RSVPSection: React.FC = () => {
    return (
        <FadeInSection>
            <section id="rsvp" className="min-h-screen flex items-center justify-center">
                <div className="px-4">
                    <ContentContainer imageSrc="abrilyjc.jpg" altText="Wedding Image">
                        <h2 className="text-2xl text-gray-700 font-bold mb-2">RSVP</h2>
                        <p className="text-gray-700 mb-4">
                            Por favor, confirma tu asistencia antes del 1 de noviembre de 2024.
                        </p>
                        <RSVPForm />
                    </ContentContainer>
                </div>
            </section>
        </FadeInSection>
    );
};

export default RSVPSection;
