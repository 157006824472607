import React from 'react';
import FadeInSection from '../components/FadeInSection';
import ContentContainer from '../components/ContentContainer';
import ImageGallery from '../components/ImageGallery';

const HomeSection: React.FC = () => {
    return (
        <FadeInSection>
            <section id="home" className="min-h-screen flex flex-col items-center justify-cente">
                <div className="px-4 mb-8">
                    <ContentContainer imageSrc="abrilyjc.jpg" altText="Wedding Image">
                        <h2 className="text-2xl text-gray-700 font-bold mb-2">30 de noviembre 2024</h2>
                        <h3 className="text-xl text-gray-700 font-bold mb-2">Cuernavaca, Morelos, México</h3>
                        <p className="text-gray-700 mb-4">
                            Estamos muy emocionados de poder compartir este día tan especial con ustedes.
                        </p>
                        <p className="text-gray-700 mb-4"> Aquí podrán conocer más detalles de nuestra boda.</p>
                        <p className="text-gray-700 mb-4"> ¡Nos vemos pronto!</p>
                        <p className="text-gray-800 mb-6">Abril & Juan Carlos</p>
                    </ContentContainer>
                </div>
                <ImageGallery />
            </section>
        </FadeInSection>
    );
};

export default HomeSection;
