import React, { useState } from 'react';
import { Link as ScrollLink } from 'react-scroll';
import { FiMenu, FiX } from 'react-icons/fi';

const MenuBar: React.FC = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    return (
        <nav className="bg-white border-b border-gray-200 fixed top-0 left-0 w-full z-10">
            <div className="flex justify-between items-center px-4 py-2 md:hidden">
                <button onClick={toggleMenu} className="text-gray-700 focus:outline-none mr-2">
                    {isOpen ? <FiX size={24} /> : <FiMenu size={24} />}
                </button>
                <span className="text-lg text-gray-700 font-bold">Menu</span>
            </div>
            <div className={`md:flex md:items-center md:justify-center ${isOpen ? 'block' : 'hidden'} px-4 pb-4 md:pb-0 md:space-x-8`}>
                <ScrollLink to="home" smooth={true} duration={500} className="block text-gray-600 hover:text-gray-900 py-2 md:py-0 px-4" onClick={() => setIsOpen(false)}>Abril & JC</ScrollLink>
                <ScrollLink to="schedule" smooth={true} duration={500} className="block text-gray-600 hover:text-gray-900 py-2 md:py-0 px-4" onClick={() => setIsOpen(false)}>Horario</ScrollLink>
                <ScrollLink to="travel" smooth={true} duration={500} className="block text-gray-600 hover:text-gray-900 py-2 md:py-0 px-4" onClick={() => setIsOpen(false)}>Cuernavaca</ScrollLink>
                <ScrollLink to="registry" smooth={true} duration={500} className="block text-gray-600 hover:text-gray-900 py-2 md:py-0 px-4" onClick={() => setIsOpen(false)}>Mesa de Regalos</ScrollLink>
                <ScrollLink to="rsvp" smooth={true} duration={500} className="block text-gray-600 hover:text-gray-900 py-2 md:py-0 px-4" onClick={() => setIsOpen(false)}>RSVP</ScrollLink>
            </div>
        </nav>
    );
};

export default MenuBar;
