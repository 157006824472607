
import React from 'react';
import { useInView } from 'react-intersection-observer';
import '../styles/ImageGallery.css'; // Create a CSS file for gallery-specific styles

const images = [
    'abrilyjc.jpg',
    'abrilyjc.jpg',
    'abrilyjc.jpg',
    'abrilyjc.jpg',
];

const ImageGallery: React.FC = () => {
    return (
        <div className="gallery-container">
            {images.map((image, index) => (
                <ImageItem key={index} image={image} index={index} />
            ))}
        </div>
    );
};

const ImageItem: React.FC<{ image: string; index: number }> = ({ image, index }) => {
    const { ref, inView } = useInView({ triggerOnce: true });

    console.log(`Image ${index + 1} in view:`, inView); // Add this line to check the inView status

    const getClassNames = () => {
        if (inView) {
            return index % 2 === 0 ? 'gallery-image in-view from-left' : 'gallery-image in-view from-right';
        }
        return 'gallery-image';
    };

    return (
        <div ref={ref} className="gallery-item">
            <img src={image} alt={`Gallery image ${index + 1}`} className={getClassNames()} />
        </div>
    );
};

export default ImageGallery;
