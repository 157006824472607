import React from 'react';

const Footer: React.FC = () => {
    return (
        <footer className="py-4 mt-auto text-center my-8">
            <p className="text-gray-700">© 2024 Abril & Juan Carlos</p>
        </footer>
    );
};

export default Footer;
