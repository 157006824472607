import React from 'react';
import FadeInSection from '../components/FadeInSection';
import ContentContainer from '../components/ContentContainer';

const TravelSection: React.FC = () => {
    return (
        <FadeInSection>
            <section id="travel" className="min-h-screen flex items-center justify-center">
                <div className="px-4">
                    <ContentContainer imageSrc="abrilyjc.jpg" altText="Wedding Image">
                        <h2 className="text-2xl text-gray-700  font-bold mb-2">Cuernavaca, Morelos</h2>
                        <p className="text-gray-700 mb-4">
                            Hoteles
                        </p>
                        <p className="text-gray-700 mb-4">
                            Recorridos
                        </p>
                        <p className="text-gray-700 mb-4">
                            Maquillaje y peinado
                        </p>
                    </ContentContainer>
                </div>
            </section>
        </FadeInSection>
    );
};

export default TravelSection;
