// translations-es.ts
import { IStrings } from "./IStrings";

const translationsEs: IStrings = {
  loading: "Cargando...",
  rsvp: "Confirmar asistencia",
  showInMap: "Ver en mapa",
  addToCalendar: "Añadir al calendario",
  rsvpFor: "Confirmar asistencia para",
  willAttend: "Asistirá",
  willNotAttend: "No asistirá",
  dietQuestion: "¿Tienes alguna alergia o restricción alimentaria?",
  dietPlaceholder: "Escribe tus alergias o restricciones alimentarias",
  bringAGuest: "Traer una invitada",
  removeGuest: "Quitar invitada",
  additionalGuestName: "Nombre de la invitada",
  additionalGuestdietQuestion: "¿Tiene la invitada alguna alergia?",
  optionalMessage: "Mensaje opcional",
  optionalMessagePlaceholder: "Escribe un mensaje para Abril y anfitrionas",
  submitRsvp: "Enviar confirmación",
  close: "Cerrar",
  errorLoadingData: "Error al cargar los datos",
};

export default translationsEs;
