// import React, { useEffect, useState } from 'react';
// import { useLocation, Routes, Route } from 'react-router-dom';
// import MainPage from './MainPage';
// import { Event } from './models/event';
// import { Guest } from './models/guest';
// import { getEventData, getGuestData } from './services/firebaseService';
// import { useStrings } from './localization/useStrings';
// import Loader from './components/Loader';
// import Home from './pages/Home';
// import Title from './components/Title';
// import MenuBar from './components/MenuBar';
// import RSVP from './pages/RSVP';
// import About from './pages/About';
// import Schedule from './pages/Schedule';
// import Registry from './pages/Registry';
// import Travel from './pages/Travel';
// import QandA from './pages/QandA';
// import Footer from './components/Footer';
// import HomeSection from './sections/HomeSection';
// import RSVPSection from './sections/RSVPSection';
// import RegistrySection from './sections/RegistrySection';
// import ScheduleSection from './sections/ScheduleSection';

// const App: React.FC = () => {
//   const strings = useStrings();
//   const location = useLocation();
//   const [eventData, setEventData] = useState<Event | null>(null);
//   const [guestData, setGuestData] = useState<Guest | null>(null);
//   const [loading, setLoading] = useState(true);

// useEffect(() => {
//   const queryParams = new URLSearchParams(location.search);
//   const guestId = queryParams.get('guestId') || '4';
//   const eventId = '1';

//   getEventData(eventId)
//     .then(data => {
//       setEventData(data);
//     })
//     .catch(error => {
//       console.error('Error fetching event data:', error);
//     });

//   getGuestData(guestId)
//     .then(data => {
//       setGuestData(data);
//       setLoading(false);
//     })
//     .catch(() => {
//       console.error('Error fetching guest data');
//       setLoading(false);
//     });
// }, [location.search]);

// if (!eventData || !guestData) {
//   return <Loader />;
// }

// website
// return (
//   <div className="bg-customGreen min-h-screen">
//     <Title />
//     <MenuBar />
//     <Routes>
//       <Route path="/" element={<Home />} />
//       <Route path="/home" element={<Home />} />
//       <Route path="/rsvp" element={<RSVP />} />
//       <Route path="/about-us" element={<About />} />
//       <Route path="/schedule" element={<Schedule />} />
//       <Route path="/registry" element={<Registry />} />
//       <Route path="/travel" element={<Travel />} />
//       <Route path="/q-and-a" element={<QandA />} />
//       {/* <Route path="/main" element={loading ? <Loader /> : <MainPage event={eventData} guest={guestData} />} /> */}
//       {/* Add other routes as needed */}
//     </Routes>
//   </div>
// );
//   return (
//     <div className="flex flex-col min-h-screen bg-customGreen">
//       <Title />
//       <MenuBar />
//       <main className="flex-grow">
//         <HomeSection />
//         <ScheduleSection />
//         <RegistrySection />
//         <RSVPSection />
//       </main>
//       <Footer />
//     </div>
//   );
// };

// export default App;

import React from 'react';
import HomeSection from './sections/HomeSection';
import ScheduleSection from './sections/ScheduleSection';
import RegistrySection from './sections/RegistrySection';
import RSVPSection from './sections/RSVPSection';
import Title from './components/Title';
import MenuBar from './components/MenuBar';
import TravelSection from './sections/TravelSection';

const App: React.FC = () => {
  return (
    <div className="flex flex-col min-h-screen bg-customGreen">
      <Title />
      <MenuBar />
      <main className="flex-grow pt-16">
        <HomeSection />
        <ScheduleSection />
        <TravelSection />
        <RegistrySection />
        <RSVPSection />
      </main>
    </div>
  );
};

export default App;
