// i18n.js
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationsEn from "./localization/translations-en";
import translationsEs from "./localization/translations-es";
i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: translationsEn,
    },
    es: {
      translation: translationsEs,
    },
  },
  lng: "en",
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
