import React, { useState, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import styled from '@emotion/styled';

const FadeInDiv = styled.div<{ isVisible: boolean }>`
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.6s ease-out, transform 0.6s ease-out;
  ${({ isVisible }) => isVisible && `
    opacity: 1;
    transform: translateY(0);
  `}
`;

const FadeInSection: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const { ref, inView } = useInView({ triggerOnce: true });
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        if (inView) {
            setIsVisible(true);
        }
    }, [inView]);

    return (
        <FadeInDiv ref={ref} isVisible={isVisible}>
            {children}
        </FadeInDiv>
    );
};

export default FadeInSection;
