import React from 'react';

const Title: React.FC = () => {
    return (
        <header className="text-center my-16">
            <h1 className="text-6xl text-gray-700 font-serif mt-6">Abril &</h1>
            <h1 className="text-6xl text-gray-700 font-serif mb-6">Juan Carlos</h1>
        </header>
    );
};

export default Title;
